import * as React from 'react';

import Layout from '../../components/layout';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="py-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              About us
            </h2>
            <h3 className="mt-4 text-2xl font-bold leading-7 text-gray-600">
              Contact info
            </h3>
            <p className="mt-2">
              Monterey Software
              <br />
              335 Hudson Rd
              <br />
              Owenton KY
              <br />
              (502) 514-9663
              <br />
            </p>
            <p className="mt-2">info@monterey.dev</p>
            <h3 className="mt-8 text-2xl font-bold leading-7 text-gray-600">
              Site design
            </h3>
            <p className="mt-2">
              This site built with{' '}
              <a href="https://www.gatsbyjs.com/">Gatsby</a> and{' '}
              <a href="https://tailwindui.com/">Tailwind UI</a>.
            </p>
            <p className="mt-2">
              Some icons made by{' '}
              <a
                href="https://www.flaticon.com/authors/pixel-buddha"
                title="Pixel Buddha"
              >
                Pixel Buddha
              </a>
              , check out{' '}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
